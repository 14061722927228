<template>
  <div>
    <div class="col float-right">
      <q-btn-group outline>
        <q-btn class="custom-btn" label="QR 코드" icon="arrow_circle_down" color="black" size="md">
          <q-popup-proxy>
            <c-card title="QR 코드 출력" class="cardClassDetailForm">
              <template slot="card-button">
                <q-btn label="인쇄" icon="save" color="blue" @click="print" />
              </template>
              <template slot="card-detail">
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div class="wrap" action="#" id="printJS-form">
                      <vue-qrcode
                        :value="'https://mshms.andami.kr/sop/edu/result/educationResult?eduEducationId='
                        + param.eduEducationId" 
                        :options="{ width: 200 }"
                        class="canvas"
                      >
                      </vue-qrcode>
                      <span class="facilityStyle">
                        <p class="abc" >
                          <b>{{'-교육명 : '}}</b>{{tabParam.educationName}}
                          <br>
                          <b>{{'-교육일시 : '}} </b>{{tabParam.educationDate}}
                          <br>
                        <!-- https:// -->
                          <!-- {{'http://localhost:20000/sop/edu/result/educationResult?eduEducationId='
                        + param.eduEducationId }} -->
                        </p>
                      </span>
                    </div>
                  </div>
                </div>
              </template>
            </c-card>
          </q-popup-proxy>
        </q-btn>
        <c-btn v-show="editable&&!disabled" :showLoading="false" label="결과완료" icon="save" color="blue"  @btnClicked="resultComplete" />
      </q-btn-group>
    </div>
    <c-tab
      type="vertical"
      :height.sync="tabHeight"
      :tabItems="tabItems"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :param="param"
          :tabParam="tabParam"
          @transInfo="transInfo"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
import printJs from 'print-js'
import VueQrcode from '@chenfengyuan/vue-qrcode';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'education-result-tab',
  components: {
    VueQrcode
  },
  props: {
    param: {
      type: Object,
      default: () => ({
        eduEducationId: '',
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      tabParam: {
        eduEducationId: '',
        educationName: '',
        eduCourseId: '',
        plantCd: '',
        educationYear: '',
        educationCourseName: '',
        educationKindCdLarge: null,
        educationKindCdSmall: null,
        educationTypeCd: null,
        departmentDeptCd: '',
        legalEducationFlag: null,
        educationTime: '',
        educationCycle: null,
        educationPurpose: '',
        relationLaws: '',
        mainTargetAudience: '',
        educationMonth: null,
        educationLocation: '',
        estimatedEducationExpenses: '',
        executionBudget: '',
        educationStartDate: '',
        educationEndDate: '',
        educationStartTime: '',
        educationEndTime: '',
        instructorInformation: '',
        sopMocId: '',
        educationMethodCd: null,
        educationOverview: '',
        evaluationEvalCd: '',
        documentStatusCd: '',
        qrCodeRoute: '',

        regUserId: '',
        chgUserId: '',
        eduSubjectList: [], // 과정별 교육과목
        eduAttendeeList: [],
      },
      completeUrl: '',
      editable: true,
      splitter: 5,
      tab: 'educationResultInfo',
    };
  },
  computed: {
    tabHeight() {
      let numHeight = this.height ? Number(this.$_.replace(this.height, 'px', '')) : 600;
      numHeight = numHeight - 40
      return numHeight + 'px';
    },
    disabled() {
      return this.tabParam.documentStatusCd === 'ESC000010'
    },
    tabItems() {
      return [
        { name: 'educationResultInfo', icon: 'edit', label: '기본정보', component: () => import(`${'./educationResultInfo.vue'}`) },
        { name: 'educationComplete', icon: 'how_to_reg', label: '교육이수', component: () => import(`${'./educationComplete.vue'}`) },
      ]
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      this.completeUrl = transactionConfig.sop.edu.result.complete.url;
      // list setting
    },
    print() {
      printJs({
        printable: 'printJS-form',
        type: 'html',
        font_size: '20px',
        targetStyles: ['*'],
      })
    },
    transInfo(data) {
      this.tabParam = data;
    },
    resultComplete() {
      let flag = false;
      if (!flag) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '결과완료하시겠습니까?(저장과 함께 처리됩니다.)',
          // TODO : 점검완료 하시겠습니까?
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.tabParam.documentStatusCd = 'ESC000010'
            this.$http.url = this.completeUrl;
            this.$http.type = 'PUT';
            this.$http.param = this.tabParam;
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
      });
      }
    },
  }
};
</script>
